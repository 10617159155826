export function Privacy() {
  return (
    <main id="Privacy">
      <section id="intro">
        <h1>Our Privacy Policy</h1>
        <p>Our Policy includes:</p>
        <li>
          Our policies and procedures on the collection, use and disclosure of
          Your information when You use the Service and tells You about Your
          privacy rights and how the law protects You.
        </li>
        <li>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </li>
      </section>
      <section id="definition">
        <h1>Definitions</h1>
        <p>For the purposes of this Privacy Policy:</p>

        <li>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </li>
        <li>
          <strong>Company</strong> (referred to as either &quot;the
          Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
          this Agreement) refers to Sharing Excess.
        </li>
        <li>
          <strong>Cookies</strong> are small files that are placed on Your
          computer, mobile device or any other device by a website, containing
          the details of Your browsing history on that website among its many
          uses.
        </li>
        <li>
          <strong>Country</strong> refers to: Pennsylvania, United States
        </li>
        <li>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </li>
        <li>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual.
        </li>
        <li>
          <strong>Service</strong> refers to the Website.
        </li>
        <li>
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.
        </li>
        <li>
          <strong>Third-party Social Media Service</strong> refers to any
          website or any social network website through which a User can log in
          or create an account to use the Service.
        </li>
        <li>
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </li>
        <li>
          <strong>Website</strong> refers to Sharing Excess, accessible from{' '}
          <a
            href="http://sharing-excess-prod.web.app"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://sharing-excess-prod.web.app
          </a>
        </li>
        <li>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
      </section>
      <section id="personal data">
        <h1>Personal Data</h1>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Usage Data</li>
      </section>
      <section id="usage data">
        <h1>Usage Data</h1>
        <p>Usage Data is collected automatically when using the Service:</p>
        <li>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </li>
        <li>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </li>
        <li>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </li>
      </section>
      <section id="third party">
        <h1>Information from Third-Party Social Media Services</h1>
        <p>
          The Company allows You to create an account and log in to use the
          Service through the following Third-party Social Media Services:
        </p>
        <li>Google</li>
        <li>Facebook</li>
        <li>Twitter</li>
        <li>
          If You decide to register through or otherwise grant us access to a
          Third-Party Social Media Service, We may collect Personal data that is
          already associated with Your Third-Party Social Media Service's
          account, such as Your name, Your email address, Your activities or
          Your contact list associated with that account.
        </li>
        <li>
          You may also have the option of sharing additional information with
          the Company through Your Third-Party Social Media Service's account.
          If You choose to provide such information and Personal Data, during
          registration or otherwise, You are giving the Company permission to
          use, share, and store it in a manner consistent with this Privacy
          Policy.
        </li>
      </section>
      <section id="tracking and cookies">
        <h1>Tracking Technologies and Cookies</h1>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <li>
          <strong>Cookies or Browser Cookies.</strong> A cookie is a small file
          placed on Your Device. You can instruct Your browser to refuse all
          Cookies or to indicate when a Cookie is being sent. However, if You do
          not accept Cookies, You may not be able to use some parts of our
          Service. Unless you have adjusted Your browser setting so that it will
          refuse Cookies, our Service may use Cookies.
        </li>
        <li>
          <strong>Flash Cookies.</strong> Certain features of our Service may
          use local stored objects (or Flash Cookies) to collect and store
          information about Your preferences or Your activity on our Service.
          Flash Cookies are not managed by the same browser settings as those
          used for Browser Cookies.
        </li>
        <li>
          <strong>Web Beacons.</strong> Certain sections of our Service and our
          emails may contain small electronic files known as web beacons (also
          referred to as clear gifs, pixel tags, and single-pixel gifs) that
          permit the Company, for example, to count users who have visited those
          pages or opened an email and for other related website statistics (for
          example, recording the popularity of a certain section and verifying
          system and server integrity).
        </li>
        <h3>
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </h3>
        <h3>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </h3>
      </section>
      <section id="use of personal data">
        <h1>Use of Your Personal Data</h1>
        <p>The Company may use Personal Data for the following purposes:</p>
        <li>
          <strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.
        </li>
        <li>
          <strong>To manage Your Account:</strong> to manage Your registration
          as a user of the Service. The Personal Data You provide can give You
          access to different functionalities of the Service that are available
          to You as a registered user.
        </li>
        <li>
          <strong>For the performance of a contract:</strong> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </li>
        <li>
          <strong>To contact You:</strong> To contact You by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </li>
        <li>
          <strong>To provide You</strong> with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </li>
        <li>
          <strong>To manage Your requests:</strong> To attend and manage Your
          requests to Us.
        </li>
        <li>
          <strong>For business transfers:</strong> We may use Your information
          to evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of Our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which Personal Data held by Us
          about our Service users is among the assets transferred.
        </li>
        <li>
          <strong>For other purposes</strong>: We may use Your information for
          other purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Service, products, services, marketing and
          your experience.
        </li>
        <p>
          We may share Your personal information in the following situations:
        </p>
        <li>
          <strong>With Service Providers:</strong> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You.
        </li>
        <li>
          <strong>For business transfers:</strong> We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of Our business to another company.
        </li>
        <li>
          <strong>With Affiliates:</strong> We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </li>
        <li>
          <strong>With business partners:</strong> We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          <strong>With other users:</strong> when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile.
        </li>
        <li>
          <strong>With Your consent</strong>: We may disclose Your personal
          information for any other purpose with Your consent.
        </li>
      </section>
      <section id="retention">
        <h1>Retention of Your Personal Data</h1>
        <h3>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </h3>
        <h3>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </h3>
      </section>
      <section id="transfer">
        <h1>Transfer of Your Personal Data</h1>
        <h3>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </h3>
        <h3>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </h3>
        <h3>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other
        </h3>
      </section>
      <section id="disclosure">
        <h1>Disclosure of Your Personal Data</h1>
        <p>Business Transactions</p>
        <li>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </li>
        <p>Law enforcement</p>
        <li>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </li>
        <p>Other legal requirements</p>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </section>
      <section id="security">
        <h1>Security of Your Personal Data</h1>
        <h3>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </h3>
      </section>
      <section id="change">
        <h1>Changes to this Privacy Policy</h1>
        <h3>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </h3>
        <h3>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </h3>
        <h3>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </h3>
      </section>
      <section id="contact">
        <h1>Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <li>By email: sharingexcesseng@gmail.com</li>
        <li>By phone: (833) 742-7397</li>
      </section>
    </main>
  )
}
