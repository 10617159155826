import { Button, Text, Input, Flex, Select } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useAuth } from 'hooks'
import { palletWeight, roundTwoPlaces } from 'helpers'

let totalWeightDebouncerTimer = null

export function WholesaleBeforeSorting({ setSummary, triggerReset, summary }) {
  const [caseCount, setCaseCount] = useState(summary.totalCaseCount || '')
  const [caseWeight, setCaseWeight] = useState(summary.averageCaseWeight || '')
  const [totalWeight, setTotalWeight] = useState(summary.totalWeight || '')
  const [palletType, setPalletType] = useState('none')
  const [jackType, setJackType] = useState('none')
  const [jackWeight, setJackWeight] = useState(0)
  const { user } = useAuth()

  useEffect(() => {
    if (
      !isNaN(caseCount) &&
      caseCount > 0 &&
      !isNaN(totalWeight) &&
      totalWeight > 0
    ) {
      clearTimeout(totalWeightDebouncerTimer)
      totalWeightDebouncerTimer = setTimeout(
        () => handleTotalWeightChange(totalWeight),
        1000
      )
    }
  }, [totalWeight, caseCount])

  useEffect(() => {
    if (triggerReset) {
      setCaseCount('')
      setCaseWeight('')
      setTotalWeight('')
      setPalletType('none')
      setJackType('none')
      setJackWeight(0)
    }
  }, [triggerReset])

  useEffect(() => {
    setPalletType(summary.palletType || '')
    setJackType(summary.jackType || '')
    setJackWeight(summary.jackWeight || '')
  }, [])

  useEffect(() => {
    setSummary(prevValue => ({ ...prevValue, palletType }))
  }, [palletType])

  useEffect(() => {
    setSummary(prevValue => ({ ...prevValue, jackType }))
  }, [jackType])

  useEffect(() => {
    setSummary(prevValue => ({ ...prevValue, jackWeight }))
  }, [jackWeight])

  useEffect(() => {
    if (summary) {
      setCaseCount(summary.totalCaseCount || '')
      setTotalWeight(summary.totalWeight || '')
    }
  }, [summary])

  const handleTotalWeightChange = value => {
    // const value = event.target.value
    let newAverageCaseWeight = value / caseCount
    setCaseWeight(roundTwoPlaces(newAverageCaseWeight))
    setTotalWeight(roundTwoPlaces(value))
  }

  const handlePalletTypeChange = (type, setType = true) => {
    let newTotalWeight = totalWeight
    // add the prev type back to the weight before subtracting
    const prevActualPalletWeight = palletWeight(palletType)
    newTotalWeight += prevActualPalletWeight

    const palletActualWeight = palletWeight(type)
    newTotalWeight -= palletActualWeight

    setTotalWeight(newTotalWeight)

    if (setType) {
      setPalletType(type)
    }
  }

  const handleJackSelectChange = event => {
    const jackNameSelected = event.target.value
    const prevJackWeight = jackWeight
    let newTotalWeight = totalWeight

    // add back to the total weight
    newTotalWeight += prevJackWeight

    let newJackWeight
    let newJackName

    if (jackNameSelected === 'none' || jackNameSelected === '') {
      // If 'none' is selected, set jack weight to 0 and recalculate total weight
      newJackWeight = 0
      newJackName = 'none'
    } else {
      const jack = user.jacks.find(jack => jack.name === jackNameSelected)
      if (jack) {
        newJackWeight = parseFloat(jack.weight)
      }
      newJackName = jackNameSelected
    }

    if (newJackWeight > newTotalWeight) {
      alert('Jack weight is higher than total weight')
      return
    }

    newTotalWeight -= newJackWeight
    setTotalWeight(newTotalWeight)
    setJackType(newJackName)
    setJackWeight(newJackWeight)
  }

  useEffect(() => {
    // Check if all three values are present and valid
    if (caseCount && caseWeight && totalWeight) {
      setSummary(prevValue => ({
        ...prevValue,
        totalCaseCount: caseCount,
        averageCaseWeight: parseFloat(caseWeight),
        totalWeight: parseFloat(totalWeight),
      }))
    }
  }, [caseCount, caseWeight, totalWeight])

  return (
    <Flex direction="column" spacing={4}>
      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Total case count
        </Text>
        <Input
          name="caseCount1stPallet"
          id="caseCount1stPallet"
          type="number"
          fontSize="sm"
          value={caseCount}
          onChange={e => setCaseCount(e.target.value)}
          placeholder="Enter case count"
        />
      </Flex>

      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Average Case Weight
        </Text>
        <Input
          name="caseWeight"
          id="caseWeight"
          type="number"
          fontSize="sm"
          value={caseWeight}
          disabled
          placeholder="Case weight will be calculated automatically (in lbs.)"
        />
      </Flex>

      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Total weight
        </Text>
        <Input
          name="totalWeight"
          id="totalWeight"
          type="number"
          min="0"
          fontSize="sm"
          value={totalWeight}
          disabled={isNaN(caseCount) || caseCount <= 0}
          onChange={event => setTotalWeight(event.target.value)}
          placeholder={
            isNaN(caseCount) || caseCount <= 0
              ? 'Enter Case Count first'
              : 'Calculated automatically or enter total weight (in lbs.)'
          }
        />
      </Flex>
      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Pallet Type
        </Text>

        <Flex direction="row" justifyContent="space-around" mb="4" mt="2">
          <Button
            variant="outline"
            colorScheme="red"
            onClick={() => handlePalletTypeChange('wood')}
            isActive={palletType === 'wood'}
          >
            Wood
          </Button>
          <Button
            variant="outline"
            colorScheme="green"
            onClick={() => handlePalletTypeChange('black')}
            isActive={palletType === 'black'}
          >
            Black
          </Button>
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={() => handlePalletTypeChange('blue')}
            isActive={palletType === 'blue'}
          >
            Blue
          </Button>
          <Button
            variant="outline"
            colorScheme="black"
            onClick={() => handlePalletTypeChange('other')}
            isActive={palletType === 'other'}
          >
            None
          </Button>
        </Flex>
      </Flex>
      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          textTranfsform="uppercase"
        >
          Jack Type
        </Text>
        <Select
          name="palletType"
          id="palletType"
          value={jackType}
          onChange={handleJackSelectChange}
        >
          <option value="none">None</option> {/* Add None option */}
          {user.jacks.map((jack, index) => (
            <option key={index} value={jack.name}>
              {jack.name} - {jack.weight} lbs
            </option>
          ))}
        </Select>
      </Flex>
    </Flex>
  )
}
