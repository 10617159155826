import { useState, useEffect } from 'react'
import { useAuth } from 'hooks'
import { Button, Text, Input, Flex, Select } from '@chakra-ui/react'
import { palletWeight, roundTwoPlaces } from 'helpers'

let totalWeightDebouncerTimer = null

export function AfterSortingPallet({
  pallet,
  onCaseCountChange,
  onTotalWeightChange,
  onPalletTypeChange,
}) {
  const [caseCount, setCaseCount] = useState('')
  const [caseWeight, setCaseWeight] = useState('')
  const [totalWeight, setTotalWeight] = useState('')
  const [palletType, setPalletType] = useState('')

  const { user } = useAuth()
  const [jackType, setJackType] = useState('none')
  const [jackWeight, setJackWeight] = useState(0)

  useEffect(() => {
    if (pallet) {
      setCaseCount(pallet.caseCount || '')
      setCaseWeight(pallet.caseWeight || '')
      setTotalWeight(pallet.totalWeight || '')
      setPalletType(pallet.palletType || '')
      setJackType(pallet.jackType || '')
    }
  }, [])

  useEffect(() => {
    if (
      !isNaN(caseCount) &&
      caseCount > 0 &&
      !isNaN(totalWeight) &&
      totalWeight > 0
    ) {
      clearTimeout(totalWeightDebouncerTimer)
      totalWeightDebouncerTimer = setTimeout(() => {
        handleTotalWeightChange(totalWeight)
      }, 1000)
    }
  }, [totalWeight, caseCount])

  const handleCaseCountChange = e => {
    const inputValue = e.target.value
    setCaseCount(inputValue)

    // Propagate the change to the parent component
    if (onCaseCountChange) {
      onCaseCountChange(Number(inputValue))
    }
  }

  const handleTotalWeightChange = value => {
    // const value = event.target.value
    const newAverageCaseWeight = value / caseCount
    setCaseWeight(roundTwoPlaces(newAverageCaseWeight))
    setTotalWeight(roundTwoPlaces(value))

    if (onTotalWeightChange) {
      onTotalWeightChange(Number(roundTwoPlaces(value)))
    }
  }

  const handlePalletTypeChange = type => {
    let newTotalWeight = totalWeight
    // add the prev type back to the weight before subtracting
    const prevActualPalletWeight = palletWeight(palletType)
    newTotalWeight += prevActualPalletWeight

    const palletActualWeight = palletWeight(type)
    newTotalWeight -= palletActualWeight

    setTotalWeight(newTotalWeight)

    setPalletType(type)
    if (onPalletTypeChange) {
      onPalletTypeChange(type)
    }
  }

  function handleJackSelectChange(event) {
    const jackNameSelected = event.target.value
    const prevJackWeight = jackWeight
    let newTotalWeight = totalWeight

    // add back to the total weight
    newTotalWeight += prevJackWeight

    let newJackWeight
    let newJackName

    if (jackNameSelected === 'none' || jackNameSelected === '') {
      // If 'none' is selected, set jack weight to 0 and recalculate total weight
      newJackWeight = 0
      newJackName = 'none'
    } else {
      const jack = user.jacks.find(jack => jack.name === jackNameSelected)
      if (jack) {
        newJackWeight = parseFloat(jack.weight)
      }
      newJackName = jackNameSelected
    }

    if (newJackWeight > newTotalWeight) {
      alert('Jack weight is higher than total weight')
      return
    }

    newTotalWeight -= newJackWeight
    setTotalWeight(newTotalWeight)
    setJackType(newJackName)
    setJackWeight(newJackWeight)
  }

  return (
    <Flex direction="column" p={6} my={-4}>
      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          textTransform="uppercase"
        >
          Case Count
        </Text>
        <Input
          type="number"
          min="0"
          fontSize="sm"
          value={caseCount}
          onChange={handleCaseCountChange}
          placeholder="Enter case count"
        />
      </Flex>

      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Case Weight
        </Text>
        <Input
          type="number"
          min="0"
          fontSize="sm"
          value={caseWeight}
          disabled
          placeholder="Case weight will be calculated automatically (in lbs.)"
        />
      </Flex>

      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Total Weight
        </Text>
        <Input
          type="number"
          min="0"
          fontSize="sm"
          disabled={isNaN(caseCount) || caseCount <= 0}
          value={totalWeight}
          onChange={event => setTotalWeight(event.target.value)}
          placeholder="Enter total weight (e.g., in lbs.)"
        />
      </Flex>
      <Flex direction="row" justifyContent="space-around" mb="4">
        <Button
          variant="outline"
          colorScheme="red"
          onClick={() => handlePalletTypeChange('wood')}
          isActive={palletType === 'wood'}
        >
          Wood
        </Button>
        <Button
          variant="outline"
          colorScheme="green"
          onClick={() => handlePalletTypeChange('black')}
          isActive={palletType === 'black'}
        >
          Black
        </Button>
        <Button
          variant="outline"
          colorScheme="blue"
          onClick={() => handlePalletTypeChange('blue')}
          isActive={palletType === 'blue'}
        >
          Blue
        </Button>
        <Button
          variant="outline"
          colorScheme="black"
          onClick={() => handlePalletTypeChange('other')}
          isActive={palletType === 'other'}
        >
          None
        </Button>
      </Flex>
      <Flex direction="column" mb="4">
        <Text
          color="element.tertiary"
          fontSize="xs"
          fontWeight="700"
          mt="6"
          textTransform="uppercase"
        >
          Jack Type
        </Text>
        <Select
          name="jackType"
          id="jackType"
          value={jackType}
          onChange={handleJackSelectChange}
          placeholder="Select jack type"
        >
          {/* <option value="none">None</option> Add None option */}
          {user.jacks.map((jack, index) => (
            <option key={index} value={jack.name}>
              {jack.name} - {jack.weight} lbs
            </option>
          ))}
        </Select>
      </Flex>
    </Flex>
  )
}
